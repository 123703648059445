/*
Required id list of element for use custom login

'custom_form' -> Form Element Id
'username' -> Username Input Element name
'username' -> Username Input Element id
'password' -> Password Input Element id
'password' -> Password Input Element name
'submit_btn' -> Submit Button Element id
'api_error_message' -> Login error message div id
'technician_portal_link' -> Technician Portal Link Tag id
'support_portal_link' -> Technician Portal Link Tag
'forgot_password_page_link' -> Forgot Password Link Tag id
*/
import Moment from 'moment'
import FormSerialize from 'form-serialize'
import { getEncryptedPassword } from '@utils/password'

export const QUERY_STRING_TEXT = '__systemPage'
// predefin element ids map
export const elementIDs = {
  CUSTOM_FORM: 'custom_form',
  SUBMIT_BUTTON: 'submit_btn',
  API_ERROR_MSG: 'api_error_message',
  API_SUCESS_MSG: 'api_success_message',
  TECH_PORTAL_LINK: 'technician_portal_link',
  SUPPORT_PORTAL_LINK: 'support_portal_link',
  FORGOT_PASSWORD_PAGE_LINK: 'forgot_password_page_link',
  MFA_RECOVERY_CODE_PAGE_LINK: 'recovery_code_link',
  MFA_RESET_BY_EMAIL_PAGE_LINK: 'reset_mfa_by_email_link',
  CAPTCHA_CONTAINER: 'captcha_container',
  CAPTCHA: 'captcha',
  REFRESH_BTN: 'refresh_btn',
  LOGIN_PAGE_LINK: 'login_page_link',
  GO_TO_HOME: 'go_to_home',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm_password',
  CANCEL_BUTTON: 'cancel_btn',
}
const getmessage = (message) => {
  return {
    response: { data: { userMessage: message } },
  }
}
// form submit handler
export function onLoginFormSubmit(loginApi, rejectFn, token) {
  return new Promise((resolve, reject) => {
    const button = document.getElementById(elementIDs.SUBMIT_BUTTON)
    button.onclick = (e) => {
      e.preventDefault()
      const form = document.getElementById(elementIDs.CUSTOM_FORM)
      const formData = FormSerialize(form, { hash: true })
      const captcha = formData.captcha
        ? { ...token, captcha: formData.captcha }
        : token
      if (!(formData.username || '').trim()) {
        return rejectFn(getmessage('Email or Logon Name field is required'))
      }
      if (!(formData.password || '').trim()) {
        return rejectFn(getmessage('Password field is required'))
      }
      return loginApi(
        formData.username,
        getEncryptedPassword(formData.password),
        captcha
      )
        .then((data) => {
          return resolve(data)
        })
        .catch((e) => {
          return rejectFn(e)
        })
    }
  })
}

// form submit handler
export function onForgotPasswordFormSubmit(forgotPasswordApi, rejectFn, token) {
  return new Promise((resolve, reject) => {
    const button = document.getElementById(elementIDs.SUBMIT_BUTTON)
    button.onclick = (e) => {
      e.preventDefault()
      const form = document.getElementById(elementIDs.CUSTOM_FORM)
      const formData = FormSerialize(form, { hash: true })
      const captcha = formData.captcha
        ? { ...token, captcha: formData.captcha }
        : token
      if (!(formData.email_or_logon_name || '').trim()) {
        return rejectFn(getmessage('Email or Logon Name field is required'))
      }
      return forgotPasswordApi(formData.email_or_logon_name, captcha)
        .then((data) => {
          return resolve(data)
        })
        .catch((e) => {
          return rejectFn(e)
        })
    }
  })
}

// two factor authentication form submit
export function onOtpFormSubmit(validateTwoFactorAuthApi, rejectFn, token) {
  return new Promise((resolve, reject) => {
    const button = document.getElementById(elementIDs.SUBMIT_BUTTON)
    button.onclick = (e) => {
      e.preventDefault()
      const form = document.getElementById(elementIDs.CUSTOM_FORM)
      const formData = FormSerialize(form, { hash: true })
      return validateTwoFactorAuthApi(token, getEncryptedPassword(formData.otp))
        .then((data) => {
          return resolve(data)
        })
        .catch((e) => {
          return rejectFn(e)
        })
    }
  })
}

// multi factor authentication form submit
export function onMfaFormSubmit(validateMultiFactorAuthApi, rejectFn, token) {
  return new Promise((resolve, reject) => {
    const button = document.getElementById(elementIDs.SUBMIT_BUTTON)
    button.onclick = (e) => {
      e.preventDefault()
      const form = document.getElementById(elementIDs.CUSTOM_FORM)
      const formData = FormSerialize(form, { hash: true })
      return validateMultiFactorAuthApi(
        token,
        getEncryptedPassword(formData.otp)
      )
        .then((data) => {
          return resolve(data)
        })
        .catch((e) => {
          return rejectFn(e)
        })
    }
  })
}

// reset multi factor authentication by email link click
export function onReserMfaByEmailClick(
  validateMultiFactorResetAuthApi,
  rejectFn,
  token
) {
  return new Promise((resolve, reject) => {
    const button = document.getElementById(
      elementIDs.MFA_RESET_BY_EMAIL_PAGE_LINK
    )
    button.onclick = (e) => {
      e.preventDefault()
      return validateMultiFactorResetAuthApi(token)
        .then((data) => {
          return resolve(data)
        })
        .catch((e) => {
          return rejectFn(e)
        })
    }
  })
}

// reset password form submit
export function onResetPasswordFormSubmit(resetPasswordApi, rejectFn, token) {
  return new Promise((resolve, reject) => {
    const button = document.getElementById(elementIDs.SUBMIT_BUTTON)
    button.onclick = (e) => {
      e.preventDefault()
      const form = document.getElementById(elementIDs.CUSTOM_FORM)
      const formData = FormSerialize(form, { hash: true })
      if (!(formData.password || '').trim()) {
        return rejectFn(getmessage('Password field is Required'))
      }
      if (!(formData.confirm_password || '').trim()) {
        return rejectFn(getmessage('Confirm Password field is Required'))
      }
      if (formData.password !== formData.confirm_password) {
        return rejectFn(
          getmessage('Password and Confirm Password must be same')
        )
      }
      return resetPasswordApi(
        {
          ...(formData.password
            ? { password: getEncryptedPassword(formData.password) }
            : {}),
        },
        token
      )
        .then((data) => {
          return resolve(data)
        })
        .catch((e) => {
          return rejectFn(e)
        })
    }
  })
}

// requester reset password form submit
export function onChangePasswordForRequesterFormSubmit(
  resetPasswordForRequesterApi,
  rejectFn,
  token
) {
  return new Promise((resolve, reject) => {
    const button = document.getElementById(elementIDs.SUBMIT_BUTTON)
    button.onclick = (e) => {
      e.preventDefault()
      const form = document.getElementById(elementIDs.CUSTOM_FORM)
      const formData = FormSerialize(form, { hash: true })
      if (!(formData.old_password || '').trim()) {
        return rejectFn(getmessage('Current Password field is Required'))
      }
      if (!(formData.new_password || '').trim()) {
        return rejectFn(getmessage('Password field is Required'))
      }
      if (!(formData.confirm_password || '').trim()) {
        return rejectFn(getmessage('Confirm Password field is Required'))
      }
      if (formData.new_password !== formData.confirm_password) {
        return rejectFn(
          getmessage('Password and Confirm Password must be same')
        )
      }
      return resetPasswordForRequesterApi(
        {
          ...(formData.old_password
            ? { currentPassword: getEncryptedPassword(formData.old_password) }
            : {}),
          ...(formData.new_password
            ? { password: getEncryptedPassword(formData.new_password) }
            : {}),
        },
        token
      )
        .then((data) => {
          return resolve(data)
        })
        .catch((e) => {
          return rejectFn(e)
        })
    }
  })
}

// requester selt signup form submit
export function onRequesterSelfSignupFormSubmit(
  requesterSelfSignupApi,
  rejectFn,
  token
) {
  return new Promise((resolve, reject) => {
    const button = document.getElementById(elementIDs.SUBMIT_BUTTON)
    button.onclick = (e) => {
      e.preventDefault()
      const form = document.getElementById(elementIDs.CUSTOM_FORM)
      const formData = FormSerialize(form, { hash: true })
      if (!(formData.name || '').trim()) {
        return rejectFn(getmessage('Full Name field is Required'))
      }
      if (!(formData.email || '').trim()) {
        return rejectFn(getmessage('Email field is Required'))
      }
      return requesterSelfSignupApi(
        {
          name: formData.name,
          email: formData.email,
          timezone: Moment.tz.guess(),
        },
        token
      )
        .then((data) => {
          return resolve(data)
        })
        .catch((e) => {
          return rejectFn(e)
        })
    }
  })
}

// forgot password link redirection
export function onForgotPasswordClick() {
  return new Promise((resolve, reject) => {
    const forgotLink = document.getElementById(
      elementIDs.FORGOT_PASSWORD_PAGE_LINK
    )
    forgotLink.onclick = (e) => {
      e.preventDefault()
      resolve()
    }
  })
}

// support portal link click funtion
export function onSupportPortalClick() {
  return new Promise((resolve, reject) => {
    const supportPortalLink = document.getElementById(
      elementIDs.SUPPORT_PORTAL_LINK
    )
    supportPortalLink.onclick = (e) => {
      e.preventDefault()
      supportPortalLink.classList.toggle('active')
      document
        .getElementById(elementIDs.TECH_PORTAL_LINK)
        .classList.remove('active')
      resolve()
    }
  })
}

export function onTechnicianPortalClick() {
  return new Promise((resolve, reject) => {
    const technicianPortalLink = document.getElementById(
      elementIDs.TECH_PORTAL_LINK
    )
    technicianPortalLink.onclick = (e) => {
      e.preventDefault()
      technicianPortalLink.classList.toggle('active')
      document
        .getElementById(elementIDs.SUPPORT_PORTAL_LINK)
        .classList.remove('active')
      resolve()
    }
  })
}

// display message
export function displayMessage(message, elementId) {
  return new Promise((resolve, reject) => {
    const errorLogin = document.getElementById(elementId)
    errorLogin.innerText = message
    resolve()
  })
}

export function createCaptchaImage(captchaImage) {
  return new Promise((resolve, reject) => {
    const div = document.getElementById(elementIDs.CAPTCHA_CONTAINER)
    div.innerHTML = '<img src=' + captchaImage + ' />'
    resolve()
  })
}

export function onElementClick(elementId) {
  return new Promise((resolve, reject) => {
    const element = document.getElementById(elementId)
    element.onclick = (e) => {
      e.preventDefault()
      resolve()
    }
  })
}

export function onRefreshButtonClick() {
  return new Promise((resolve, reject) => {
    const button = document.getElementById(elementIDs.REFRESH_BTN)
    button.onclick = (e) => {
      e.preventDefault()
      resolve()
    }
  })
}

export function onGoToHomeButtonClick() {
  return new Promise((resolve, reject) => {
    const button = document.getElementById(elementIDs.GO_TO_HOME)
    button.onclick = (e) => {
      e.preventDefault()
      resolve()
    }
  })
}

export function redirectToLogin() {
  return new Promise((resolve, reject) => {
    const login = document.getElementById(elementIDs.LOGIN_PAGE_LINK)
    login.onclick = (e) => {
      e.preventDefault()
      resolve()
    }
  })
}

export function hideFormItems(itemsId) {
  return new Promise((resolve, reject) => {
    itemsId.forEach((id) => {
      document.getElementById(id).style.display = 'none'
    })
    resolve()
  })
}

export function showFormItems(itemsId) {
  return new Promise((resolve, reject) => {
    itemsId.forEach((id) => {
      document.getElementById(id).style.display = 'block'
    })
    resolve()
  })
}

export function getFormPreview(iframe, value) {
  var previewFrame = iframe // document.getElementById('preview');
  var preview =
    previewFrame.contentDocument || previewFrame.contentWindow.document
  preview.open()
  preview.write(value)
  preview.close()
}

/** **********************************Validatins ************************************************/

export const formValidationMap = {
  page_login: loginPageValidaion,
  page_forgot_password: forgotPasswordPageValidation,
  page_reset_password: resetPasswordPageValidation,
  page_otp: optPageValidation,
  page_mfa: mfaPageValidation,
  page_requester_reset_password: requesterResetPasswordPageValidation,
  page_requester_self_register: requesterSelfRegisterPageValidation,
  page_200: successPageValidation,
  page_500: errorPageValidation,
  page_404: pageNotFoundPageValidation,
}

export function scriptTagValidation($) {
  const script = $('script')
  let error = null
  if (script.length > 0) {
    error = 'Script tag is not allowed.'
  }
  return error
}

export function formTagValidation($) {
  const form = $(`form#${elementIDs.CUSTOM_FORM}`)
  let error = null
  if (form.length === 0) {
    error = `Form tag is not present with id "${elementIDs.CUSTOM_FORM}"`
  } else if (form.length > 1) {
    error = `Form tag found with id "${elementIDs.CUSTOM_FORM}" is more then one`
  }
  return error
}

export function inputTagValidation($, element, type = 'text') {
  const input = $(`input#${element}`)
  const parentElement = $(`form#${elementIDs.CUSTOM_FORM} input#${element}`)
  let error = null
  if (input.length === 0) {
    error = `Input tag is not present with name "${element}" and id "${element}"`
  } else if (input.length > 1) {
    error = `Input tag found with name "${element}" and id "${element}" is more then one`
  } else if (parentElement.length === 0) {
    error = `Input tag with id "${element}" must be inside Form tag`
  } else if (input[0].attribs.name !== element) {
    error = `Input tag with id "${element}" name attribute value must be "${element}"`
  } else if (input[0].attribs.type !== type) {
    error = `Input tag with id "${element}" type attribute value must be "${type}"`
  }
  return error
}

export function buttonTagValidation($, element) {
  const button = $(`button#${element}`)
  const parentElement = $(`form#${elementIDs.CUSTOM_FORM} button#${element}`)
  let error = null
  if (button.length === 0) {
    error = `Button tag is not present with id "${element}"`
  } else if (button.length > 1) {
    error = `Button tag found with id "${element}" is more then one`
  } else if (parentElement.length === 0) {
    error = `Button tag with id "${element}" must be inside Form tag`
  } else if (button[0].attribs.type !== 'button') {
    error = `Button tag with id "${element}" type attribute value must be "button"`
  }
  return error
}

export function elementTagValidation($, element, type) {
  const tag = $(`#${element}`)
  let error = null
  if (tag.length === 0) {
    error = `Element is not present with id "${element}"`
  } else if (tag.length > 1) {
    error = `Element found with id "${element}" is more then one`
  } else if (type && type.indexOf(tag[0].name) === -1) {
    error = `Element with id "${element}" is must be ${type.join(' or ')} tag`
  } else if (tag[0].name === 'button' && tag[0].attribs.type !== 'button') {
    error = `Button tag with id "${element}" type attribute value must be "button"`
  }
  return error
}

// login page validation
export function loginPageValidaion($) {
  let error = null
  error = scriptTagValidation($)
  if (error) {
    return error
  }
  error = formTagValidation($)
  if (error) {
    return error
  }
  error = inputTagValidation($, 'username')
  if (error) {
    return error
  }
  error = inputTagValidation($, 'password', 'password')
  if (error) {
    return error
  }
  error = inputTagValidation($, 'captcha')
  if (error) {
    return error
  }
  error = buttonTagValidation($, 'submit_btn')
  if (error) {
    return error
  }
  error = elementTagValidation($, 'support_portal_link')
  if (error) {
    return error
  }
  error = elementTagValidation($, 'technician_portal_link')
  if (error) {
    return error
  }
  error = elementTagValidation($, 'api_error_message', ['div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'captcha_container', ['div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'refresh_btn', ['button', 'a'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'forgot_password_page_link', ['a', 'div'])
  if (error) {
    return error
  }
  return error
}

// forgot password page validation
export function forgotPasswordPageValidation($) {
  let error = null
  error = scriptTagValidation($)
  if (error) {
    return error
  }
  error = formTagValidation($)
  if (error) {
    return error
  }
  error = inputTagValidation($, 'email_or_logon_name')
  if (error) {
    return error
  }
  error = inputTagValidation($, 'captcha')
  if (error) {
    return error
  }
  error = buttonTagValidation($, 'submit_btn')
  if (error) {
    return error
  }
  error = elementTagValidation($, 'api_error_message', ['div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'captcha_container', ['div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'refresh_btn', ['button', 'a'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'login_page_link', ['a', 'div'])
  if (error) {
    return error
  }
  return error
}

// reset password page validation
export function resetPasswordPageValidation($) {
  let error = null
  error = scriptTagValidation($)
  if (error) {
    return error
  }
  error = formTagValidation($)
  if (error) {
    return error
  }
  error = inputTagValidation($, 'password', 'password')
  if (error) {
    return error
  }
  error = inputTagValidation($, 'confirm_password', 'password')
  if (error) {
    return error
  }
  error = buttonTagValidation($, 'submit_btn')
  if (error) {
    return error
  }
  error = elementTagValidation($, 'api_error_message', ['div'])
  if (error) {
    return error
  }
  return error
}

// otp page validation
export function optPageValidation($) {
  let error = null
  error = scriptTagValidation($)
  if (error) {
    return error
  }
  error = formTagValidation($)
  if (error) {
    return error
  }
  error = inputTagValidation($, 'otp', 'password')
  if (error) {
    return error
  }
  error = buttonTagValidation($, 'submit_btn')
  if (error) {
    return error
  }
  error = elementTagValidation($, 'refresh_btn', ['button', 'a'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'api_error_message', ['div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'api_success_message', ['div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'login_page_link', ['a', 'div'])
  if (error) {
    return error
  }
  return error
}
// mfa page validation
export function mfaPageValidation($) {
  let error = null
  error = scriptTagValidation($)
  if (error) {
    return error
  }
  error = formTagValidation($)
  if (error) {
    return error
  }
  error = inputTagValidation($, 'otp', 'password')
  if (error) {
    return error
  }
  error = buttonTagValidation($, 'submit_btn')
  if (error) {
    return error
  }
  error = elementTagValidation($, 'login_page_link', ['a', 'div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'recovery_code_link', ['a', 'div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'reset_mfa_by_email_link', ['a', 'div'])
  if (error) {
    return error
  }
  return error
}

// requester reset password page validation
export function requesterResetPasswordPageValidation($) {
  let error = null
  error = scriptTagValidation($)
  if (error) {
    return error
  }
  error = formTagValidation($)
  if (error) {
    return error
  }
  error = inputTagValidation($, 'old_password', 'password')
  if (error) {
    return error
  }
  error = inputTagValidation($, 'new_password', 'password')
  if (error) {
    return error
  }
  error = inputTagValidation($, 'confirm_password', 'password')
  if (error) {
    return error
  }
  error = buttonTagValidation($, 'submit_btn')
  if (error) {
    return error
  }
  error = elementTagValidation($, 'api_error_message', ['div'])
  if (error) {
    return error
  }
  return error
}

// reqeuster selt registration page validation
export function requesterSelfRegisterPageValidation($) {
  let error = null
  error = scriptTagValidation($)
  if (error) {
    return error
  }
  error = formTagValidation($)
  if (error) {
    return error
  }
  error = inputTagValidation($, 'name')
  if (error) {
    return error
  }
  error = inputTagValidation($, 'email', 'email')
  if (error) {
    return error
  }
  error = inputTagValidation($, 'captcha')
  if (error) {
    return error
  }
  // error = inputTagValidation($, 'password', 'password')
  // if (error) {
  //   return error
  // }
  // error = inputTagValidation($, 'confirm_password', 'password')
  // if (error) {
  //   return error
  // }
  error = buttonTagValidation($, 'submit_btn')
  if (error) {
    return error
  }
  error = elementTagValidation($, 'api_error_message', ['div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'captcha_container', ['div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'refresh_btn', ['button', 'a'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'cancel_btn', ['button'])
  if (error) {
    return error
  }
  return error
}

// success page validation
export function successPageValidation($) {
  let error = null
  error = scriptTagValidation($)
  if (error) {
    return error
  }
  error = elementTagValidation($, 'api_success_message', ['div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'go_to_home', ['a', 'button'])
  if (error) {
    return error
  }
  return error
}

// error page validation
export function errorPageValidation($) {
  let error = null
  error = scriptTagValidation($)
  if (error) {
    return error
  }
  error = elementTagValidation($, 'api_error_message', ['div'])
  if (error) {
    return error
  }
  error = elementTagValidation($, 'go_to_home', ['a', 'button'])
  if (error) {
    return error
  }
  return error
}

// page not found page validation
export function pageNotFoundPageValidation($) {
  let error = null
  error = scriptTagValidation($)
  if (error) {
    return error
  }
  error = elementTagValidation($, 'go_to_home', ['a', 'button'])
  if (error) {
    return error
  }
  return error
}
